import React, { SVGProps } from "react";

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg version="1.1" x="0px" y="0px" width={props.width} height={props.height}
       viewBox="0 0 466.35 378.35"  {...props}>
    <g id="konfigurator.APP" aria-label="Konfigurator.APP">
      <defs>
        <linearGradient id="Unbenannter_Verlauf" x1="271.85" y1="502.91" x2="271.85" y2="13.53"
                        gradientTransform="matrix(1, 0, 0, -1, 0, 514)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#00efd1"/>
          <stop offset="1" stopColor="#00acea"/>
        </linearGradient>
      </defs>
      <path
        d="M310.31,105.59a38.4,38.4,0,1,0-76.79,0A37.89,37.89,0,0,0,264,143.16V218.7a37.92,37.92,0,0,0-30.51,37.57,38.43,38.43,0,0,0,30.37,37.56l.08,75.52a38.39,38.39,0,1,0,16,0l-.11-75.57A38.41,38.41,0,0,0,280,218.7V143.16A38.75,38.75,0,0,0,310.31,105.59Zm-60.92,0A22.41,22.41,0,1,1,271.8,128,22.42,22.42,0,0,1,249.39,105.59ZM294.2,406.94a22.41,22.41,0,1,1-22.4-22.41A22.41,22.41,0,0,1,294.2,406.94Zm0-150.67a22.41,22.41,0,1,1-22.4-22.41A22.4,22.4,0,0,1,294.2,256.27Z"
        transform="translate(-22.65 -67)" fill="url(#Unbenannter_Verlauf)"/>
      <path d="M444,76.27a8,8,0,0,0-8-8H337a8,8,0,0,0-8,8v58a8,8,0,0,0,8,8h99a8,8,0,0,0,8-8Zm-99,8h83v42H345Z"
            transform="translate(-22.65 -67)" fill="url(#Unbenannter_Verlauf)"/>
      <path d="M489,227.27a8,8,0,0,0-8-8H337a8,8,0,0,0-8,8v58a8,8,0,0,0,8,8H481a8,8,0,0,0,8-8Zm-16,50H345v-42H473Z"
            transform="translate(-22.65 -67)" fill="url(#Unbenannter_Verlauf)"/>
      <path d="M444,378.26a8,8,0,0,0-8-8H337a8,8,0,0,0-8,8v58a8,8,0,0,0,8,8h99a8,8,0,0,0,8-8Zm-16,50H345v-42h83Z"
            transform="translate(-22.65 -67)" fill="url(#Unbenannter_Verlauf)"/>
      <g>
        <path
          d="M194.87,176.22H32.78v20.26H194.87ZM205,277.52V257.26l-10.13-50.65H32.78L22.65,257.26v20.26H32.78v60.79H134.09V277.52h40.52v60.79h20.26V277.52Zm-91.17,40.53H53V277.52h60.79Z"
          transform="translate(-22.65 -67)" fill="url(#Unbenannter_Verlauf)"/>
      </g>
      </g>
  </svg>
);

export default Logo;
