import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import Logo from "../layout/Logo";

/*
const useStyles = makeStyles({
    media: {
        height: '18em',
    },
});
*/

const Welcome = () => {
    const translate = useTranslate();
    //const classes = useStyles();
    return (
        <Card>
            <CardContent>
                <Typography variant="h4" component="h3" align={"center"}>

                    <Logo width={100} /> {translate('pos.dashboard.welcome.title')}
                </Typography>
                <Typography component="p" align={"center"}>
                    {translate('pos.dashboard.welcome.subtitle')}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default Welcome;
