import { useEffect, useState } from "react";
import { httpClient } from "./index";

export const useFetchTerraceSizes = () => {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    httpClient('/api/terrace/sizes')
      .then(res => res.json())
      .then(json => {
        setLoading(false);
        if (json) {
          setSizes(json)
        } else {
          setSizes([])
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  }, []);
  return { sizes, isSizesLoading: loading, isSizesError: error }
};
