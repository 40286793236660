export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            light: '#5f5fc4',
            main: '#A67D74',
            dark: '#001064',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#27853b',
            dark: '#001064',
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            light: '#718792',
            main: '#455a64',
            dark: '#1c313a',
            contrastText: '#fff',
        },
        secondary: {
            light: '#62727b',
            main: '#37474f',
            dark: '#102027',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};
