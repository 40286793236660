import React from 'react';
import Typography from "@material-ui/core/Typography";
import {
  FormControlLabel,
  Grid,
  Radio, RadioGroup,
  Switch,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StoreContainer } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      //backgroundColor: '#f9f9f9',
    },
    radio: {
      '&&': {
        position: 'absolute',
        opacity: 0,
        width: 0,
        height: 0,
      },
      '&& + img': {
        cursor: 'pointer',
      },
      '&&:checked + img': {
        outline: '2px solid #37474f',
      },
      '&& + img + div': {
        cursor: 'pointer',
      },
      '&&:checked + img + div': {
        backgroundColor: '#37474f',
        color: '#fff',
      },
    },
    media: {
      height: 100,
    },
  }),
);

const TerraceService = () => {
  const classes = useStyles();
  const store = StoreContainer.useContainer();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="inherit" component="h2">
          Statik / Montage
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography  gutterBottom variant="inherit" component="h3">
          Statik / Gemeindespefzifische Bauordnung
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
        control={<Switch checked={store.serviceStatic} onChange={store.toggleServiceStatic} />}
        label="Statikbericht zur Vorlage bei der Gemeinde / Bauamt gewünscht"
      />
      </Grid>
      <Grid item xs={12}>
        <Typography  gutterBottom variant="inherit" component="h3">
          Montage
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup aria-label="gender" name="gender2" value={store.serviceMontage} onChange={store.handleChangeServiceMontage}>
          <FormControlLabel
            value="Endkunde"
            control={<Radio color="primary" />}
            label="Montage durch Endkunden"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Fachfirma"
            control={<Radio color="primary" />}
            label="Montage durch Fachfirma"
            labelPlacement="end"
          />
        </RadioGroup>

        Bei der Montage durch eine Montagefirma fallen zusätzliche Kosten an, welche direkt von der Montagefirma berechnet werden. Montagepreise siehe „Aufmaß und Montageservice Premium Bauelemente“
      </Grid>
    </Grid>
  )
};

export default TerraceService;
