import { useEffect, useState } from "react";
import { httpClient } from "./index";

export const useFetchTerraceImages = () => {
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [size, setSize] = useState('5100x3060');
  const [color, setColor] = useState('anthrazit');
  const [cover, setCover] = useState('acryl_klar');

  useEffect(() => {
    setLoading(true);
    setError(null);

    const wl = size.split('x');

    const data = {
      width: wl[0],
      length: wl[1],
      color: color,
      cover: cover,
    };

    httpClient('/api/terrace/images', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        setLoading(false);
        if (json) {
          setImages(json)
        } else {
          setImages(null)
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  }, [size, color, cover]);
  return [{
    images, isDataLoading: loading, isDataError: error
  },
    setSize, setColor, setCover
  ]
};
