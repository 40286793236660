import germanMessages from 'ra-language-german';

const i18n = {
    ...germanMessages,
    pos: {
        search: 'Suche',
        configuration: 'Konfiguration',
        language: 'Sprache',
        theme: {
            name: 'Thema',
            light: 'Hell',
            dark: 'Dunkel',
        },
        dashboard: {
            welcome: {
                title: 'Konfigurator',
                subtitle:
                    "für Premium Terrassendächer und Carports",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            terrace: 'Terrassendach',
            carport: 'Carport',
            customers: 'Kunden',
            help: 'Hilfe',
            home: 'Home',
        },
    },
    resources: {
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                customerNo: 'Kunden-Nr.',
                customerNoName: 'Kunde',
                company: 'Firma',
                address: 'Adresse',
                country: 'Land',
                name: 'Name',
                name2: 'Name 2',
                name3: 'Name 3',
                zip: 'PLZ',
                city: 'Ort',
                phone: 'Telefon',
                email: 'E-Mail',
            },
            fieldGroups: {
                identity: 'Allgemein',
                address: 'Addresse',
                stats: 'Stats',
                visits: 'Besuche',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Kunden löschen',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        terrace: {
            name: 'Terrassendach Konfigurator',
            fields: {
            },
            fieldGroups: {
            },
            errors: {
            },
        },
        carport: {
            name: 'Carport Konfigurator',
            fields: {
            },
            fieldGroups: {
            },
            errors: {
            },
        },
        reports: {
            name: 'Tagesbericht |||| Tagesberichte',
            amount: '1 Tagesbericht |||| %{smart_count} Tagesberichte',
            title: 'Tagesbericht: %{reference}',
            unit: 'km',
            hour: 'Uhr',
            fields: {
                customer_id: 'Kunde',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
                reportDate: 'Datum',
                license: 'Kennzeichen',
                kmStart: 'KM Start',
                kmEnd: 'KM Ende',
                kmPriv: 'KM Privat',
                tourStart: 'Tour Start',
                tourEnd: 'Tour Ende',
                overnights: 'Übernachtung',
                type: 'Art',
                calendarWeek: 'KW   ',
            },
            fieldGroups: {
                date: 'Tagesbericht',
                car: 'Fahrzeug',
                visits: 'Besuche',
                general: 'Sonstiges',
            },
        },
        visits: {
            name: 'Besuch |||| Besuche',
            amount: '1 Besuch |||| %{smart_count} Besuche',
            title: 'Besuch: %{reference}',
            fields: {
                customer: 'Kunde',
                status: 'Status',
                report: 'Bericht',
                visitedAt: 'Datum',
                visitedFrom: 'Von',
                visitedTo: 'Bis',
                type: 'Terminart',
                type1: 'Art 1',
                type2: 'Art 2',
                sort: 'Art',
                comments: 'Kommentare',
            },
            fieldGroups: {
                create_visit: 'Besuch erstellen',
                car: 'Fahrzeug',
                visits: 'Besuche',
                general: 'Sonstiges',
            },
        },
        documentation: {
            name: 'Dokumentation |||| Dokumentation',
            title: 'Besuch: %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Kunde',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        faq: {
            name: 'FAQ |||| FAQs',
            amount: '1 Besuch |||| %{smart_count} Besuche',
            title: 'Besuch: %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Kunde',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
    },
    Unauthorized: 'Benutzername oder Passwort ungültig',
};

// overwrites
i18n.ra.auth.auth_check_error  = 'Bitte melden Sie sich an';
i18n.ra.auth.username  = 'Benutzername';
i18n.ra.notification.logged_out  = 'Sie wurden ausgeloggt';

export default i18n;
