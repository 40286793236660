import { AuthProvider } from 'ra-core';

const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const request = new Request(`/api/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => {
                localStorage.setItem('token', access_token);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem('token');
        const request = new Request(`/api/check`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        request.headers.set('Authorization', `Bearer ${token}`);
        return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  localStorage.removeItem('token');
                  return Promise.reject();
              }
              return Promise.resolve();
          });
    },
    getPermissions: () => Promise.reject('Unknown method'),
};

export default authProvider;
