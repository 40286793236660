import { useEffect, useState } from "react";
import { httpClient } from "./index";

export const useFetchTerraceData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [size, setSize] = useState('5100x3060');
  const [color, setColor] = useState('anthrazit');
  const [ral, setRalColor] = useState(null);
  const [cover, setCover] = useState('acryl_klar');
  const [wall, setWall] = useState('');
  const [serviceStatic, setServiceStatic] = useState(false);
  const [serviceMontage, setServiceMontage] = useState('Fachfirma');

  useEffect(() => {
    setLoading(true);
    setError(null);

    const wl = size.split('x');

    const data = {
      width: wl[0],
      length: wl[1],
      color: color,
      colorRal: ral,
      cover: cover,
      wall: wall,
      configurable: false,
      serviceStatic: serviceStatic,
      serviceMontage: serviceMontage,
    };

    httpClient('/api/terrace/data', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        setLoading(false);
        if (json) {
          setData(json)
        } else {
          setData(null)
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  }, [size]);
  return [{
    data, isDataLoading: loading, isDataError: error,
    color,
    ral,
    cover,
    wall,
    serviceStatic,
    serviceMontage,
  },
    setSize,
    setColor,
    setRalColor,
    setCover,
    setWall,
    setServiceStatic,
    setServiceMontage,
  ]
};
