import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import TerraceShow from './terrace/TerraceShow';
import CarportShow from './carport/CarportShow';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/terrace" render={() => <TerraceShow />} />,
    <Route exact path="/carport" render={() => <CarportShow />} />,
];
