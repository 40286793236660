
export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  const token = localStorage.getItem('token');
  if ("set" in options.headers) {
    // @ts-ignore
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetch(url, options);
};
